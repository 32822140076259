<template>
  <b-row>
    <b-col cols="3">
      <h4 class="mb-0">{{ name }}</h4>
      <p class="mb-0">({{ id }})</p>
    </b-col>
    <b-col cols="9">
      <b-form-input
          v-model="fieldValue"
          debounce="500"
          @update="updateValue"
          :disabled="!hasWriteAccess"
      >
      </b-form-input>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'TextField',
  props: {
    name: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    hasWriteAccess: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    fieldValue: null,
  }),
  created() {
    this.fieldValue = this.value;
  },
  watch: {
    value() {
      this.fieldValue = this.value;
    },
  },
  methods: {
    updateValue() {
      this.$emit('update', this.fieldValue);
    },
  },
};
</script>

<style scoped lang="scss">
.personalization-settings #content input {
  color: #495057;
  border: 1px solid #ced4da;
  background-color: #fff;
}

h4, p {
  font-size: 15px;
}

h4 {
  font-weight: 600;
  svg {
    cursor: pointer;
  }
}
/deep/ .dropdown .btn-secondary:hover {
  color: #b9b9b9;
}
</style>
