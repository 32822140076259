var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    [
      _c("b-col", { attrs: { cols: "3" } }, [
        _c("h4", { staticClass: "mb-0" }, [_vm._v(_vm._s(_vm.name))]),
        _c("p", { staticClass: "mb-0" }, [_vm._v("(" + _vm._s(_vm.id) + ")")])
      ]),
      _c(
        "b-col",
        { attrs: { cols: "9" } },
        [
          _c("b-form-input", {
            attrs: { debounce: "500", disabled: !_vm.hasWriteAccess },
            on: { update: _vm.updateValue },
            model: {
              value: _vm.fieldValue,
              callback: function($$v) {
                _vm.fieldValue = $$v
              },
              expression: "fieldValue"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }